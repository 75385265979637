@tailwind base;
@tailwind components;
@tailwind utilities;

/* @import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap'); */

/* @import url('https://fonts.googleapis.com/css2?family=Noto+Serif:wght@500;700&display=swap'); */

/* @import url('https://fonts.googleapis.com/css2?family=Roboto+Serif:wght@500;700&display=swap'); */

/* tailwind course */

/* Hamburger Menu */
.hamburger {
  cursor: pointer;
  width: 24px;
  height: 24px;
  transition: all 0.25s;
  /* because spans inside will be positioned absolute */
  position: relative;
}

.hamburger-top,
.hamburger-middle,
.hamburger-bottom {
  position: absolute;
  width: 24px;
  height: 2px;
  top: 0;
  left: 0;
  background: #fff;
  transform: rotate(0);
  transition: all 0.5s;
}

.hamburger-middle {
  transform: translateY(7px);
}

.hamburger-bottom {
  transform: translateY(14px);
}

.open {
  transform: rotate(90deg);
}

.open .hamburger-top {
  transform: rotate(45deg) translateY(6px) translateX(6px);
}

.open .hamburger-middle {
  display: none;
}

.open .hamburger-bottom {
  transform: rotate(-45deg) translateY(6px) translateX(-6px);
}
/* ____ */

.project-filter {
  margin: 30px auto;
}

.project-filter nav {
  display: flex;
  padding: 10px;
  background-color: #fff;
  border-radius: 4px;
}

.project-filter p {
  font-size: 0.9em;
  margin-right: 10px;
}

.project-filter button {
  background: transparent;
  border: 0;
  font-family: inherit;
  font-weight: bold;
  color: var(--text-color);
  cursor: pointer;
  border-right: 1px solid #e4e4e4;
  font-size: 0.9em;
}

.project-filter button:last-child {
  border: 0;
}

.project-filter button.active {
  color: var(--primary-color);
}

/* ____ */

:root {
  --primary: #efb6b2;
  --secondary: #4e4e4e;
  --error: #ff4a4a;
}

/* base styles & title */

body {
  /* font-family: 'Noto Serif'; */
  color: var(--secondary);
}

.App {
  max-width: 960px;
  margin: 0 auto;
}

/* .title h1 {
  color: var(--primary);
  font-size: 1.2rem;
  letter-spacing: 2px;
  font-weight: normal;
}

.title h2,
.title p {
  text-align: center;
}

.title h2 {
  margin-top: 60px;
  font-size: 2.6rem;
} */

/* upload form styles */

/* form {
  margin: 30px auto 10px;
  text-align: center;
} */

/* label input {
  height: 0;
  width: 0;
  opacity: 0;
} */

label {
  display: block;
  width: 30px;
  height: 30px;
  border: 1px solid var(--primary);
  border-radius: 50%;
  margin: 10px auto;
  line-height: 30px;
  color: var(--primary);
  font-weight: bold;
  font-size: 24px;
}

label:hover {
  background: var(--primary);
  color: white;
}

.output {
  height: 60px;
  font-size: 0.8rem;
}

.error {
  color: var(--error);
}

/* progress bar styles */

.progress-bar {
  height: 5px;
  background: var(--primary);
  margin-top: 20px;
}

/* image grid styles */

.img-grid {
  margin: 20px auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 40px;
}

/* padding controls height, will always be perfectly square regardless of width */

.img-wrap {
  overflow: hidden;
  height: 0;
  padding: 50% 0;
  position: relative;
  opacity: 0.8;
}

.img-wrap img {
  min-width: 100%;
  min-height: 100%;
  max-width: 150%;
  /* changed: */
  /* max-width: 100%;
  max-height: 50%; */
  position: absolute;
  top: 0;
  left: 0;
}

/* image grid styles 2 */

/* First the Grid */

.gallery-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 1.5rem;
  justify-items: center;
  margin: 0;
  padding: 0;
}

/* The Picture Frame */

.gallery-frame {
  padding: 0.5rem;
  font-size: 1.2rem;
  text-align: center;
  background-color: rgb(38, 73, 115);
  color: #e9ebed;
  opacity: 0.95;
}

/* The Images */

.gallery-img {
  max-width: 100%;
  height: auto;
  object-fit: cover;
  transition: opacity 0.25s ease-in-out;
  opacity: 1;
}

/* .gallery-img:hover {
  opacity: 0.3;
} */

/* modal styles */

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.backdrop img {
  display: block;
  max-width: 60%;
  max-height: 80%;
  margin: 60px auto;
  box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.5);
  border: 3px solid white;
}

/* Pedro Styles */

/* img {
  width: 50%;
  height: 100%;
  margin: 10px;
} */

/* .App_Pedro {
  width: 100vw;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
} */
